.course {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 3px solid gold;
    background: white;
}

.boyne-img {
    height: 150px;
    background: white;
    border: 2px solid gold;
    border-radius: 5%;
}

@media screen and (max-width: 767px) {
    .boyne-img {
        height: 275px;
        width: 100%;
        max-width: 350px
    }
}

@media screen and (max-width: 400px) {
    .boyne-img {
        height: 200px;
        width: 200px;
        max-width: 350px;
        padding: 0px;
    }

    .main-btn {
        /* height: 490px; */
        padding: 2px;
        margin-top: 20px;
    }
}



@media screen and (min-width: 767px) {
    .boyne-img {
        height: 275px;
        width: 80%;
        max-width: 650px;
    }
}

.boyne-divider {
    background: gold;
    height: 4px;
}


.discounts {
    color: green;
    font-weight: bold;
}

.row-space {
    margin: 30px 0px;
}

.shop-btn-features {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    background: linear-gradient(to right, #037257 0%, #15c39ac9 100%);
    /* background: linear-gradient(to right, #15c39ac9 0%, #037257 100%); */
    border: 4px solid goldenrod;
    position: relative;
    margin: 0px 20px;
}

.fit-img {
    object-fit: contain;
}

.btn-book {
    font-weight: bold;
    text-decoration: italic;
    font-style: italic;
    background: linear-gradient(to right, rgb(155, 2, 2) 0%, red 100%);
}

.previousPrice {
    color: red;
    text-decoration: line-through;
    margin-left: 10px;
    text-decoration-thickness: 3px;
    text-decoration-color: black;
}

.package-deal {
    font-size: 14px;
    color: green;
}

.feature-title {
    margin-bottom: 40px;
}

.deals-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-width {
    width: 100%;
}