.title {
    color: green !important;
}
.float-btn{
	position: fixed;
    width: 100px;
    height: 100px;
    bottom: 40px;
    right: 40px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    font-size: 38px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

a:visited {
    color: white;
}