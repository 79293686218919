.pdf {
    font-size: 20px;
    cursor: pointer;
}

.pdf:visited {
    color: purple;
}

.links {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.pdf-icon {
    margin-right: 10px;
}

.learn-more {
    margin-top: 30px;
}