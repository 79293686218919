.bg-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  z-index: 2;
  backface-visibility: hidden;
  opacity: 0.3;
  background: linear-gradient(70deg, black, black);
}

#myVideo {
  object-fit: cover;
  object-position: 44% 50%;
}

.main-btn {
  display: block;
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  z-index: 5;
  animation: fadeinup 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  opacity: 0;
  color: gold;
}

.main-btn-deals {
  display: block;
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -10%);
  z-index: 5;
  animation: fadeinup 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  opacity: 0;
  color: gold;

}

.btn-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btn-container-main .a {
  margin-top: 30px;
}

.deals-main {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 300px;
}

.main-book-btn {
  padding: 16px 40px;
}

@keyframes fadeinup {
  from {
    opacity: 0;
    top: 55%
  }

  to {
    opacity: 1;
    top: 50%
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    filter: hue-rotate(45deg);
    -webkit-filter: hue-rotate(45deg);
  }

  to {
    top: 50%;
    filter: hue-rotate(0deg);
    -webkit-filter: hue-rotate(0deg);
  }
}

.header-container {
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 100vh;
  }

  @media screen and (min-width: 768px) {
    height: 100%;
  }

  position: relative;
}



.shop-btn {
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  background: linear-gradient(to right, #ffffffc9 0%, #aeb2b0 100%);
  border: 4px solid white;
  color: gold !important;
  width: 90%;

  p {
    color: #000 !important;
  }
}

.shop-btn:hover {
  background: linear-gradient(to right, #aeb2b0 0%, #ffffffc9 100%);
}

.post-header {
  z-index: 10;
  width: 100%;
  overflow: hidden;
  height: 100%;
  background-color: #567DA7;
  background-size: cover;
  background-position: center center;
  filter: hue-rotate(45deg);
  -webkit-filter: hue-rotate(45deg);
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAABlUlEQVQYlQXBvWsTYQDA4d97d7mL8e5y1CQ0VLQQIpF+iC5SBDdxU0GoKKi7i4N/g1JwcnAXHNyrICg41lETbXGQ0Kr56lmby9d95O59fR6x8eh+5+D7nq28CtduPFQHzRZ3rzYoFaTaPbS5sNrg5toRj5++YWBv8OzJHTXs/piI+oPLKnZOE87B73VhZlJd1Fkuunz+6nPr+gqvNld5/clnu1fl4mILM3iPePnu+SiRlqMJC6kExskcnWGOFEHGEXlZwWy22f7whfrmPdYbfSydsXH7Sg2JBFEgzRKkTOj0/yD0hKq3RKsd8XHgUz6/Q80ecK58CSnOIt7uvBjNkn+OVDGWZTCL+6TpLrmcxyxd4oRpk9N/YRb+MhtNmETrZNIcG4M+ZMpFcUg4D5mmK2SsgYJ6VZAE39jrmKDVEFqKzEJMI8HwiqETTHymURdNK+IVz6BYJoqmmNY+42CfYdBHaWUQHqVTedI0cYxQhr+DOHF0sYBra/jHW0JDY64qNH+GxJkk7y7Q6x0LQ2+jREmkmTv+D9PPtwW7DylpAAAAAElFTkSuQmCC');
}

.post-header {
  transform: translateZ(0);
}

.post-header-enhanced {
  animation: fadein .5s both;
}

.hidden {
  visibility: hidden;
}

@keyframes sharpen {
  from {
    background-image: filter(blur(20px));
  }

  to {
    background-image: filter(blur(0px));
  }
}